import { colorTokens, type Skin } from '@orus.eu/pharaoh'
import { PoweredByOrus } from '../powered-by-orus'
import avatarUrl from './avatar.svg'
import HorizontalLogoSvg from './logo'
import quoteHelpBannerImageUrl from './quote-help-banner-image.svg'

const interUrl = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap'
const interFontFamily = 'inter'

export const legalPlaceSkin: Skin = {
  avatarUrl,
  quoteCardHelperBackgroundColor: colorTokens['color-bg-decorative-1'],
  quoteHelpBannerImageUrl: {
    fr: quoteHelpBannerImageUrl,
    es: quoteHelpBannerImageUrl,
  },
  horizontalLogo: {
    aspectRatio: 71 / 14,
    ImageComponent: HorizontalLogoSvg,
  },
  showByOrusInHeader: 'by',
  TrustComponent: PoweredByOrus,
  forcedColors: {
    color: colorTokens['color-text-base-main'],
    backgroundColor: colorTokens['color-text-decorative-2'],
  },
  subscriptionCalloutColors: {
    backgroundColor: '#1F0F69',
    accentColor: '#E7E4F4',
    textColor: '#E7E4F4',
  },
  stripe: {
    fontFamily: interFontFamily,
    fontFamilyUrl: interUrl,
  },
  additionalStylesheets: [],
  cssVariables: {
    '--skin-blue-100': 'var(--Blue-100)',
    '--skin-blue-200': 'var(--Blue-200)',
    '--skin-blue-300': 'var(--Blue-300)',
    '--skin-blue-400': 'var(--Blue-400)',
    '--skin-blue-500': 'var(--LP_Blue-500)',
    '--skin-blue-600': 'var(--LP_Blue-600)',
    '--skin-blue-700': 'var(--LP_Blue-700)',
    '--skin-blue-800': 'var(--LP_Blue-800)',
    '--skin-blue-900': 'var(--LP_Blue-900)',
    '--skin-gray-100': 'var(--LP_Grey-50)',
    '--skin-gray-200': 'var(--LP_Grey-100)',
    '--skin-gray-300': 'var(--LP_Grey-150)',
    '--skin-gray-400': 'var(--LP_Grey-200)',
    '--skin-gray-500': 'var(--LP_Grey-300)',
    '--skin-gray-600': 'var(--LP_Grey-400)',
    '--skin-gray-700': 'var(--LP_Grey-600)',
    '--skin-gray-800': 'var(--LP_Grey-600)',
    '--skin-gray-900': 'var(--LP_Grey-600)',
    '--skin-green-100': 'var(--Green-100)',
    '--skin-green-200': 'var(--Green-200)',
    '--skin-green-300': 'var(--Green-300)',
    '--skin-green-400': 'var(--Green-400)',
    '--skin-green-500': 'var(--Green-500)',
    '--skin-green-600': 'var(--Green-600)',
    '--skin-green-700': 'var(--Green-700)',
    '--skin-green-800': 'var(--Green-800)',
    '--skin-green-900': 'var(--Green-900)',
    '--skin-yellow-100': 'var(--Yellow-100)',
    '--skin-yellow-200': 'var(--Yellow-200)',
    '--skin-yellow-300': 'var(--Yellow-300)',
    '--skin-yellow-400': 'var(--Yellow-400)',
    '--skin-yellow-500': 'var(--Yellow-500)',
    '--skin-yellow-600': 'var(--Yellow-600)',
    '--skin-yellow-700': 'var(--Yellow-700)',
    '--skin-yellow-800': 'var(--Yellow-800)',
    '--skin-yellow-900': 'var(--Yellow-900)',
    '--skin-orange-100': 'var(--Orange-100)',
    '--skin-orange-200': 'var(--Orange-200)',
    '--skin-orange-300': 'var(--Orange-300)',
    '--skin-orange-400': 'var(--Orange-400)',
    '--skin-orange-500': 'var(--Orange-500)',
    '--skin-orange-600': 'var(--Orange-600)',
    '--skin-orange-700': 'var(--Orange-700)',
    '--skin-orange-800': 'var(--Orange-800)',
    '--skin-orange-900': 'var(--Orange-900)',
    '--skin-red-100': 'var(--Red-100)',
    '--skin-red-200': 'var(--Red-200)',
    '--skin-red-300': 'var(--Red-300)',
    '--skin-red-400': 'var(--Red-400)',
    '--skin-red-500': 'var(--Red-500)',
    '--skin-red-600': 'var(--Red-600)',
    '--skin-red-700': 'var(--Red-700)',
    '--skin-red-800': 'var(--Red-800)',
    '--skin-red-900': 'var(--Red-900)',
    '--skin-white': 'var(--White)',
    '--skin-black': 'var(--Black)',
    '--skin-linen': 'var(--White)',
    '--skin-sky-dark': 'var(--LP_Decorative)',
    '--skin-sky-light': 'var(--LP_Decorative)',
    '--skin-sky-gradient': 'radial-gradient(400% 100% at 0% 100%, var(--LP_Decorative) 0%, var(--LP_Decorative) 100%)',
    '--skin-periwinkle-dark': 'var(--LP_Decorative)',
    '--skin-periwinkle-light': 'var(--LP_Decorative)',
    '--skin-periwinkle-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--LP_Decorative) 0%, var(--LP_Decorative) 100%)',
    '--skin-mindaro-dark': 'var(--LP_Decorative)',
    '--skin-mindaro-light': 'var(--LP_Decorative)',
    '--skin-mindaro-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--LP.Decorative) 0%, var(--LP.Decorative) 100%)',
    '--skin-jasmine-dark': 'var(--LP_Decorative)',
    '--skin-jasmine-light': 'var(--LP_Decorative)',
    '--skin-jasmine-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--LP_Decorative) 0%, var(--LP_Decorative) 100%)',
    '--skin-peach-dark': 'var(--LP_Decorative)',
    '--skin-peach-light': 'var(--LP_Decorative)',
    '--skin-peach-gradient':
      'radial-gradient(400% 100% at 0% 100%, var(--LP_Decorative) 0%, var(--LP_Decorative) 100%)',
  },
}
